import {memo, useMemo} from 'react';

import {debounce} from 'lodash';

import VerificationForm from '~/shared/components/VerificationForm';
import {BackButton, BackButtonSvg} from '~/shared/components/loginStyled';
import {trackEvent} from '~/shared/services/analytics';
import {VerificationFormContextProvider} from '~/common/contextProviders/VerificationFormContextProvider';

import {LOGIN_PROVIDER_ANALYTICS_MAP} from './consts';

const LoginVerificationForm = ({
  onSubmitVerification,
  emailEntry,
  verificationDetails,
  onSubmitEmail,
  onBackClick,
  pageView,
  resErrors,
  setResErrors,
  t,
  isStrongLogin,
  fromCheckout,
  loginProvider,
}) => {
  const linkType = verificationDetails.isPhoneMethod ? '2FA - SMS' : '2FA - Email';

  const reSendCode = useMemo(() => {
    return debounce(() => {
      trackEvent('hasClickedSendCodeAgain', {linkType, onCheckout: fromCheckout, authenticationProvider: LOGIN_PROVIDER_ANALYTICS_MAP[loginProvider]});
      onSubmitEmail({email: emailEntry, shouldSendTrackEvent: false});
    }, 500);
  }, [emailEntry, fromCheckout, linkType, loginProvider, onSubmitEmail]);

  return (
    <>
      <BackButton pageView={pageView} onClick={onBackClick} aria-label={t('back')}>
        <BackButtonSvg />
      </BackButton>
      <VerificationFormContextProvider>
        <VerificationForm
          onReSendCode={reSendCode}
          onSubmit={onSubmitVerification}
          email={emailEntry}
          headerText={
            verificationDetails?.isPhoneMethod
              ? t(
                  isStrongLogin
                    ? 'strong_login_we_sent_you_an_sms_with_code_to_this_number_xxx_withPrefix'
                    : 'we_sent_you_an_sms_with_code_to_this_number_xxx_withPrefix',
                  {lastFourPhoneDigits: verificationDetails.lastFourPhoneDigits},
                )
              : t(
                  isStrongLogin
                    ? 'strong_login_we_sent_you_an_email_with_code_to_verify'
                    : 'we_sent_you_an_email_with_code_to_verify',
                )
          }
          resErrors={resErrors}
          setResErrors={setResErrors}
        />
      </VerificationFormContextProvider>
    </>
  );
};

export default memo(LoginVerificationForm);
