/* eslint-disable class-methods-use-this */

import store from '../store';
import {User} from '../store/models';
import {restrictedSharedActions} from '../store/actions';
import {SignUpCredentials, UserCredentials} from '../store/storeModules/restrictedSharedActions';
import {setUserSessionToken} from '../utils/userToken';
import {trackEvent} from '../services/analytics';
import {selectCurrentAddress} from '../store/selectors';
import {LoginRegistrationProvider, LoginRegistrationProviders} from '../services/analytics/analyticsModels';

export const updateCookiesAndToken = async (user: User) => {
  setUserSessionToken(user.sessionToken);
};

export interface LoginAnalyticsProps {
  onCheckout?: boolean;
  authenticationProvider?: LoginRegistrationProvider;
  isAutomaticLogin?: boolean;
}

const UserManager = {
  login: async (userCredentials: UserCredentials, analyticsProps: LoginAnalyticsProps) => {
    const user: User = await store.dispatchThunk(restrictedSharedActions.login(userCredentials));
    updateCookiesAndToken(user);
    trackEvent('login', {
      userData: user,
      currentAddressCityName: selectCurrentAddress(store.getState())?.cityName,
      ...analyticsProps,
    });
    return user;
  },

  registerUser: async (
    signUpCredentials: SignUpCredentials & {
      authenticationCode?: string;
      authenticationToken?: string;
    },
    analyticsDataProps: {
      onCheckout?: boolean;
      authenticationProvider: LoginRegistrationProviders;
    },
    registerCompanyUser?: boolean,
  ) => {
    const user: User = await store.dispatchThunk(
      restrictedSharedActions.registerUser({
        signUpCredentials: {
          ...signUpCredentials,
          activationCode: signUpCredentials.authenticationCode,
          activationToken: signUpCredentials.authenticationToken,
        },
        registerCompanyUser,
      }),
    );
    updateCookiesAndToken(user);
    trackEvent('register', {userData: user, analyticsDataProps});
    return user;
  },
};

export default UserManager;
