import {useCallback} from 'react';

import {useLocation} from '~/shared/router';
import {signInModalOrPage} from '~/shared/services/navigation';

const useOpenSignIn = () => {
  const location = useLocation();
  const createOpenSignInAction = useCallback(
    (mode: 'login' | 'signUp') => () => {
      return signInModalOrPage({mode, returnUrl: location.path});
    },
    [location],
  );

  return {
    openLogin: createOpenSignInAction('login'),
    openSignUp: createOpenSignInAction('signUp'),
  };
};
export default useOpenSignIn;
