import {memo, useState, useEffect, useCallback, useMemo} from 'react';

import {useDispatch, useSelector} from 'react-redux';

import {trackEvent} from '~/shared/services/analytics';
import {createLogger} from '~/shared/logging';
import {getLocalizationService} from '~/shared/services/localisationService';
import actions from '~/shared/store/actions';
import {selectUserData} from '~/shared/store/selectors';
import {CentralLoading} from '~/shared/components/Loaders';
import {LoginBody, BodyWrapper} from '~/shared/components/loginStyled';
import {onSubmitEmailHandler} from '~/shared/components/SignIn/utils/authHelpers';
import {LoginRegistrationProviders} from '~/shared/services/analytics/analyticsModels';

import {LOGIN_SCREEN_TYPES, LOGIN_PROVIDER_ANALYTICS_MAP} from './consts';
import LoginVerificationForm from './LoginVerificationForm';
import PasswordForm from './PasswordForm';
import LoginForm from './LoginForm';

const logger = createLogger('Login');

const LOGIN_COMPONENTS = {
  [LOGIN_SCREEN_TYPES.email]: LoginForm,
  [LOGIN_SCREEN_TYPES.verification]: LoginVerificationForm,
  [LOGIN_SCREEN_TYPES.password]: PasswordForm,
};

const getAnalyticNameByScreenType = ({currentLoginScreen, verificationDetails}) => {
  const loginScreenAnalyticsNames = {
    [LOGIN_SCREEN_TYPES.email]: '2FA - Enter Email',
    [LOGIN_SCREEN_TYPES.password]: '2FA - B2B FTO',
    [LOGIN_SCREEN_TYPES.verification]: verificationDetails?.isPhoneMethod ? '2FA - SMS' : '2FA - Email',
  };

  return loginScreenAnalyticsNames[currentLoginScreen];
};

const Login = ({
  backgroundColor,
  onBackButtonClick,
  pageView,
  scrollModalToBottom,
  onSubmit,
  initialLoginEmail,
  isStrongLogin,
  openProviderScreen,
  isRedirectedToStepRef,
  verificationDetails,
  setVerificationDetails,
  fromCheckout,
  loginProvider,
  ...props
}) => {
  const [currentLoginScreen, setCurrentLoginScreen] = useState(LOGIN_SCREEN_TYPES.email);
  const [emailEntry, setEmailEntry] = useState();
  const [resErrors, setResErrors] = useState();
  const dispatch = useDispatch();
  const userData = useSelector(selectUserData);

  const isStrongLoginAutoSubmitting = useMemo(() => {
    return isStrongLogin && !!userData?.email && currentLoginScreen === LOGIN_SCREEN_TYPES.email;
  }, [currentLoginScreen, isStrongLogin, userData?.email]);

  const PresentedComponent = LOGIN_COMPONENTS[currentLoginScreen];

  useEffect(() => {
    setResErrors(null);
    if (!isStrongLogin) {
      dispatch(actions.clearUserWithLoaded());
    }
  }, [currentLoginScreen, dispatch, isStrongLogin]);

  const {t} = getLocalizationService();
  const linkType = getAnalyticNameByScreenType({currentLoginScreen, verificationDetails});

  const onSubmitEmail = useCallback(
    async ({email, shouldSendTrackEvent = true}) => {
      trackEvent('loginForm_click', {onCheckout: fromCheckout, authenticationProvider: LoginRegistrationProviders.EMAIL});
      onSubmitEmailHandler({
        email,
        setEmailEntry,
        setResErrors,
        shouldSendTrackEvent,
        linkType,
        setVerificationDetails,
        setCurrentLoginScreen,
        fromCheckout,
        loginProvider,
      });
    },
    [fromCheckout, linkType, setVerificationDetails, loginProvider],
  );

  useEffect(() => {
    if (isStrongLoginAutoSubmitting) {
      onSubmitEmail({email: userData.email, shouldSendTrackEvent: false});
    }
  }, [isStrongLoginAutoSubmitting, onSubmitEmail, userData?.email]);

  const onSubmitVerification = async entries => {
    trackEvent('loginOTP_submit', {onCheckout: fromCheckout, authenticationProvider: LOGIN_PROVIDER_ANALYTICS_MAP[loginProvider]});

    const {authenticationCode} = entries;
    if (!authenticationCode) {
      logger.error('onSubmitVerification didnt receive an authenticationCode');
      return;
    }

    trackEvent('hasClickedLogin', {linkType});
    setResErrors(null);
    if (!isStrongLogin) {
      dispatch(actions.clearUserWithLoaded());
    }
    onSubmit({email: emailEntry, authenticationToken: verificationDetails.authenticationToken, ...entries}, linkType);
  };

  const onSubmitLoginWithEmailAndPassword = async ({password, ...rest}) => {
    trackEvent('hasClickedLogin', {linkType});
    onSubmit({email: emailEntry, password, ...rest}, linkType);
  };

  const onForgotPassword = () => {
    trackEvent('hasClickedForgotPassword', {linkType});
    dispatch(actions.resetPassword({emailAddress: emailEntry}));
    dispatch(
      actions.setCurrentModal('successModal', {
        title: 'reset_password',
        text: 'an_email_to_complete_setting_new_password_process_was_sent_to_you',
        btnText: 'ok',
      }),
    );
  };

  useEffect(() => {
    trackEvent('hasViewedLoginStep', {linkType});
  }, [linkType]);

  useEffect(() => {
    if (scrollModalToBottom) {
      window.addEventListener('resize', scrollModalToBottom);
    }

    return () => {
      if (scrollModalToBottom) {
        window.removeEventListener('resize', scrollModalToBottom);
      }
    };
  }, [scrollModalToBottom]);

  return (
    <>
      {isStrongLoginAutoSubmitting && !resErrors && <CentralLoading />}
      <BodyWrapper
        id="login_tab_controls"
        aria-labelledby="login_tab_button"
        role="tabpanel"
        pageView={pageView}
        backgroundColor={backgroundColor}
      >
        <LoginBody>
          <PresentedComponent
            {...{
              onBackButtonClick,
              backgroundColor,
              onSubmitEmail,
              verificationDetails,
              onSubmitVerification,
              emailEntry,
              resErrors,
              setResErrors,
              onForgotPassword,
              onBackClick: () => setCurrentLoginScreen(LOGIN_SCREEN_TYPES.email),
              onSubmitLoginWithEmailAndPassword,
              initialValues: {email: initialLoginEmail},
              t,
              pageView,
              isStrongLogin,
              openProviderScreen,
              isRedirectedToStepRef,
              fromCheckout,
              loginProvider,
              ...props,
            }}
          />
        </LoginBody>
      </BodyWrapper>
    </>
  );
};

export default memo(Login);
