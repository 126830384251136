import {LoginRegistrationProviders} from '~/shared/services/analytics/analyticsModels';

export enum ViewModes {
  SIGN_UP_MODE = 'signUp',
  SIGN_IN_MODE = 'loginOrRegister',
}

export enum LoginProvider {
  GOOGLE = 'Google',
  FACEBOOK = 'Facebook',
  TENBIS = 'Tenbis'
}

export const LOGIN_PROVIDER_ANALYTICS_MAP = {
  [LoginProvider.GOOGLE]: LoginRegistrationProviders.GOOGLE,
  [LoginProvider.FACEBOOK]: LoginRegistrationProviders.FACEBOOK,
  [LoginProvider.TENBIS]: LoginRegistrationProviders.EMAIL,
};

export enum LoginOrRegisterScreenKey {
  LOGIN = 'login',
  SIGN_UP = 'signUp',
  PROVIDER = 'provider'
}

export enum AuthFlowStep {
  INITIAL,
  PASSWORD,
  OTP
}

export const LOGIN_SCREEN_TYPES = {
  email: 'EMAIL_STEP',
  password: 'PASSWORD_STEP',
  verification: 'VERIFICATION_STEP',
};
